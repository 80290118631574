import { ArrowButton, Footer, HomeModal } from "@bay1/ui";
import { MarketingNav } from "@bay1/ui/components/MarketingNav";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import { trackGoal } from "fathom-client";
import Head from "next/head";
import Link from "next/link";
import React, { useCallback, useState } from "react";

import { HeroTextIntro } from "../components/animations/icons/HeroTextIntro";
import { HomeBrand } from "../components/animations/icons/HomeBrand";
import { HomeCollab } from "../components/animations/icons/HomeCollab";
import { HomeCustomers } from "../components/animations/icons/HomeCustomers";
import { HomeDash } from "../components/animations/icons/HomeDash";
import { HomeEnhance } from "../components/animations/icons/HomeEnhance";
import { HomeGraph } from "../components/animations/icons/HomeGraph";
import { HomeHero } from "../components/animations/icons/HomeHero";
import { HomeHeroBack } from "../components/animations/icons/HomeHeroBack";
import { HomeLedger } from "../components/animations/icons/HomeLedger";
import { HomeMarquee } from "../components/animations/icons/HomeMarquee";
import { HomeMove } from "../components/animations/icons/HomeMove";
import { HomeNetworks } from "../components/animations/icons/HomeNetworks";
import { HomeNotifications } from "../components/animations/icons/HomeNotifications";
import { HomePlan } from "../components/animations/icons/HomePlan";
import { HomeSpend } from "../components/animations/icons/HomeSpend";
import { LogosMarquee } from "../components/animations/icons/LogosMarquee";
import { ProductsHero } from "../components/animations/icons/ProductsHero";
import { formium } from "../lib/formium";

const Marketing = (): JSX.Element => {
  const [modalProperties, setModalProperties] = useState({
    isVisible: false,
    headline: "",
    message: "",
  });

  const handleNewsletterFormSuccess = useCallback(async () => {
    setModalProperties({
      isVisible: true,
      headline: "Thanks for signing up",
      message: "The Highnote team will be in touch.",
    });
  }, []);

  return (
    <>
      <Head>
        <title>
          Highnote | Most Modern Card Issuer Processor and Program Management
          Platform
        </title>
        <meta
          content="Harness the power of a customer-first all-in-one modern card issuer processing and program management platform to quickly build and launch new revenue streams for your digital business."
          name="description"
          key="desc"
        />
        <meta property="og:title" content="Highnote" />
        <meta
          property="og:description"
          content="The World&#39;s Most Modern Card Platform."
        />
        <meta content="https://highnote.com/" property="og:url" />
        <meta content="website" property="og:type" />
        <meta
          name="google-site-verification"
          content="m3GXBVlAjdod1tpHzcs7PLlUfATzcDdpSjD0JdhjeYI"
        />
        <meta property="og:image" content="/img/og/home-og.jpg" />
      </Head>
      {/* Marquee Section */}
      <div className="to-bone bg-gradient-to-b from-white antialiased">
        <MarketingNav backgroundClass="bg-transparent" />
        <HomeModal {...modalProperties} />
        <HomeHeroBack />
        <main className="relative flex-auto overflow-hidden px-5 pb-20 pt-10 lg:px-10 lg:pb-40">
          <div className="relative mx-auto w-full max-w-7xl">
            <div className="w-full">
              <h1 className="headline font-display text-4xl lg:text-7xl">
                <HeroTextIntro />
              </h1>
            </div>
          </div>
          <div className="container mx-auto w-full max-w-7xl justify-between gap-10 pt-6 lg:flex lg:pt-14">
            <div className="w-full lg:w-1/2">
              <p className="max-w-md text-base lg:text-lg">
                Meet the new standard for a modern card platform. Launch your
                product, issue cards, and grow your revenue.
              </p>
              <Link
                href="/request-demo"
                className="button button-large button-green mt-10"
                onClick={() => trackGoal("OL4X51JW", 0)}
              >
                Talk to an Expert
                <ArrowButton isPrimary />
              </Link>
              <Link
                href={`${DASHBOARD_URL}/auth/signin?screen_hint=signup`}
                className="button button-large group"
                onClick={() => trackGoal("VHSLUFFQ", 0)}
              >
                Get API Keys
                <span className="inline-block">
                  <img
                    alt=""
                    className="translate-x-1 duration-200 ease-in-out group-hover:translate-x-2"
                    src="/img/black-arrow-icon.svg"
                  />
                </span>
              </Link>
              <div className="mt-20 h-10 lg:mt-40">
                <LogosMarquee />
              </div>
            </div>
            <div className="relative mx-auto lg:w-1/2">
              <div className="relative hidden lg:block">
                <HomeHero />
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Better */}
      <section className="bg-bone px-5 pb-20 antialiased lg:px-10 lg:pb-32 lg:pt-0">
        <div className="border-ash relative mx-auto max-w-7xl justify-between gap-12 border-t pt-20 lg:flex lg:pt-32">
          <div className="lg:w-1/2">
            <h2 className="font-display text-2xl lg:text-6xl xl:text-7xl">
              A better way to launch a card product
            </h2>
          </div>
          <div className="lg:w-1/2">
            <p className="mt-4 max-w-xl text-base md:text-lg">
              Highnote’s issuer processor platform brings together everything
              you need to issue cards, support your customers, and stay
              compliant. From building your integration to operating at scale,
              Highnote streamlines the entire card issuance process.
            </p>
            <a
              className="button button-medium button-black mt-12"
              href="/platform"
              onClick={() => trackGoal("F7XIUVQB", 0)}
            >
              Discover Our Platform
              <ArrowButton isPrimary />
            </a>
          </div>
        </div>
        <div className="relative mx-auto max-w-7xl items-center justify-between pt-16 lg:flex lg:pt-32">
          <div className="grid w-full grid-cols-1 gap-5 text-center sm:grid-cols-2 lg:grid-cols-3 lg:gap-10">
            <div className="rounded-highnote bg-white p-5">
              <HomeNetworks />
              <div className="pb-5 pt-10">
                Receive rich transaction data via direct connections to the
                networks
              </div>
            </div>
            <div className="rounded-highnote relative bg-white p-5">
              <HomeCustomers />
              <div className="pb-5 pt-10">
                Automate identity verification with powerful know-your-customer
                workflows
              </div>
            </div>
            <div className="rounded-highnote bg-white p-5">
              <HomeEnhance />
              <div className="pb-5 pt-10">
                Enhance your product with virtual deposit accounts, digital
                wallets, and more
              </div>
            </div>
            <div className="rounded-highnote bg-white p-5">
              <HomeLedger />
              <div className="pb-5 pt-10">
                Track funds movement and balances with the integrated ledger
              </div>
            </div>
            <div className="rounded-highnote bg-white p-5">
              <HomeSpend />
              <div className="pb-5 pt-10">
                Control and optimize authorizations with spend rules and
                velocity controls
              </div>
            </div>
            <div className="rounded-highnote bg-white p-5">
              <HomeCollab />
              <div className="pb-5 pt-10">
                Approve or decline each transaction based on your business logic
                with collaborative auth
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Make */}
      <section className="bg-bone pb-20 antialiased lg:px-10 lg:pb-32">
        <div className="relative mx-auto max-w-7xl px-5 lg:px-0">
          <div className="lg:w-full">
            <h2 className="font-display mx-auto max-w-4xl text-2xl sm:text-center lg:text-7xl">
              Make it Your Own
            </h2>
          </div>
        </div>
        <div className="container relative mx-auto flex max-w-7xl flex-col justify-between gap-14 overflow-hidden px-5 pt-5 sm:gap-20 md:overflow-visible md:pt-32 lg:flex-row lg:px-0">
          <div className="order-2 sm:-ml-40 lg:order-1 lg:-ml-80 lg:w-1/2 xl:-ml-64">
            <HomeBrand />
          </div>
          <div className="order-1 lg:order-2 lg:w-1/2">
            <p className="max-w-xl text-base lg:text-lg">
              Put your brand in your customer’s hands. Highnote provides a
              white-label platform to build your unique brand experience. Manage
              the design of everything from your app or site, to cards, and
              mailers. Your brand comes through at every touchpoint.
            </p>
            <Link
              href="/request-demo"
              className="button button-medium button-black mt-10"
              onClick={() => trackGoal("JPNI0N4N", 0)}
            >
              Plan Your Launch
              <ArrowButton isPrimary />
            </Link>
          </div>
        </div>
      </section>
      {/* Products */}
      <section className="bg-bone px-5 pb-20 antialiased lg:px-10 lg:pb-40">
        <div className="relative mx-auto max-w-7xl">
          <div className="lg:w-full">
            <h2 className="font-display text-2xl lg:text-7xl">
              Infinitely Flexible Products
            </h2>
          </div>
          <div className="mt-5 w-full gap-16 md:mt-32 lg:flex">
            <div className="flex flex-col justify-between pb-10 md:pb-0 lg:w-1/2">
              <div>
                <p className="max-w-xl pr-4 text-base lg:text-lg">
                  Quickly create custom and differentiated products tailored to
                  your business. Say goodbye to pre-selected buckets, slow
                  launch times, and the inability to extend your brand. Highnote
                  has a product that can grow and adapt with your business
                  needs.
                </p>
                <Link
                  href="/products"
                  className="button button-medium button-black mt-10 w-max"
                  onClick={() => trackGoal("HLKNO8TS", 0)}
                >
                  View Our Products
                  <ArrowButton isPrimary />
                </Link>
              </div>
            </div>
            <div className="rounded-page relative h-[600px] items-center overflow-hidden bg-white py-10 md:flex md:justify-center lg:w-1/2">
              <ProductsHero />
            </div>
          </div>
        </div>
      </section>
      {/* Modern */}
      <section className="bg-bone px-5 pb-20 antialiased lg:px-10 lg:pb-20">
        <div className="rounded-page relative mx-auto  max-w-7xl bg-black px-5 py-28 pb-32 text-white lg:px-16">
          <div className="lg:w-full">
            <h2 className="font-display mx-auto max-w-4xl text-2xl sm:text-center lg:text-7xl">
              A modern stack for modern companies
            </h2>
          </div>
          <div className="container relative mx-auto flex max-w-7xl flex-col items-center justify-between gap-12 pt-5 sm:pt-12">
            <div className="w-full sm:text-center">
              <p className="mx-auto max-w-3xl text-base lg:text-lg">
                Highnote’s GraphQL API and capabilities such as Notifications
                and SDKs enable your team to bring a card product to market
                quickly. The flexible platform allows your team to focus on
                customer and product experiences while we handle the rest.
              </p>
              <div className="mx-auto mt-12 flex items-center gap-2 sm:justify-center">
                <Link
                  href={`${DASHBOARD_URL}/auth/signin?screen_hint=signup`}
                  className="button button-medium bg-white text-black"
                  onClick={() => trackGoal("7BQJSZ9R", 0)}
                >
                  Start Building
                  <ArrowButton isPrimary />
                </Link>
                <Link
                  href="/docs"
                  className="button button-medium group text-white"
                  onClick={() => trackGoal("5HGXKUBM", 0)}
                >
                  Read Docs
                  <span className="inline-block">
                    <img
                      alt=""
                      className="translate-x-1 duration-200 ease-in-out group-hover:translate-x-2"
                      src="/img/white-arrow-icon.svg"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="mt-12 w-full lg:mt-16">
              <div className="justify-between space-y-12 lg:flex lg:space-x-20 lg:space-y-0">
                <div className="w-full lg:w-1/3">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="h-12">
                      <HomeGraph />
                    </div>
                    <h3 className="pt-1 text-lg">GraphQL API</h3>
                    <p className="max-w-sm text-sm">
                      Rapidly build flexible, production-ready, integrations
                      with Highnote’s GraphQL API.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="-ml-2 h-12">
                      <HomeNotifications />
                    </div>
                    <h3 className="pt-1 text-lg">Notifications & Webhooks</h3>
                    <p className="max-w-sm text-sm">
                      Power your experience and customer communications with
                      event subscriptions.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="h-12">
                      <HomeDash />
                    </div>
                    <h3 className="pt-1 text-lg">No-Code Dashboard</h3>
                    <p className="max-w-sm text-sm">
                      Manage and support from the Dashboard with visibility
                      across your entire product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-bone px-5 pb-20 pt-20 antialiased lg:px-10 lg:pb-32 lg:pt-20">
        <div className="relative mx-auto flex w-full max-w-7xl flex-col gap-10 lg:flex-row">
          <div className="rounded-page w-full gap-20 bg-white px-5 py-10 md:flex lg:p-10">
            <div className="w-full md:w-1/2">
              <h3 className="max-w-2xl text-base lg:text-lg">
                &quot;We have been growing fast, and so have the diverse payment
                preferences of our customers. That’s why we’re thrilled to
                partner with Highnote, who has demonstrated a similar speed at
                which they can get things done. With virtually issued cards, our
                customers can now navigate their software and infrastructure
                acquisitions with greater choice, unlocking a universe of
                possibilities to fuel their most innovative ideas.&quot;
              </h3>
              <p className="mt-6 flex max-w-sm items-center gap-2">
                <img
                  alt=""
                  className="mr-1 h-10 w-10 rounded-full"
                  src="img/mark-ghermezian.webp"
                />
                <span className="font-medium">Mark Ghermezian</span> CEO
              </p>
            </div>
            <div className="bg-bone rounded-highnote mt-8 flex w-full items-center justify-center py-10 md:mt-0 md:w-1/2 md:py-0">
              <img alt="" src="img/logo-gynger.svg" />
            </div>
          </div>
        </div>
      </section>
      {/* Side-by-Side */}
      <section className="bg-white px-5 pb-20 pt-20 antialiased lg:px-10 lg:pb-24 lg:pt-32">
        <div className="relative mx-auto flex w-full max-w-7xl flex-col gap-10 lg:flex-row">
          <div className="bg-bone rounded-page px-5 py-10 text-center lg:w-1/2 lg:p-10">
            <h3 className="font-display text-2xl lg:text-4xl">
              Plan Your Launch
            </h3>
            <p className="mx-auto mt-4 max-w-sm">
              Fast access to everything you need to launch your first card
              product.
            </p>
            <div className="mx-auto mt-7 flex justify-center gap-2">
              <Link
                href="/request-demo"
                className="button button-medium button-black"
                onClick={() => trackGoal("2RDUKC7M", 0)}
              >
                Talk to an Expert
                <ArrowButton isPrimary />
              </Link>
            </div>
            <div className="pb-4 pt-12 lg:pb-8 lg:pt-14">
              <div className="hidden sm:block">
                <HomePlan />
              </div>
              <div className="sm:hidden">
                <img alt="" src="img/plan-your-launch.svg" />
              </div>
            </div>
          </div>
          <div className="bg-bone rounded-page px-5 py-10 text-center lg:w-1/2 lg:p-10">
            <h3 className="font-display text-2xl lg:text-4xl">
              Move to Highnote
            </h3>
            <p className="mx-auto mt-4 max-w-sm">
              Detailed documentation and experts to help you migrate your card
              product to a modern platform.
            </p>
            <div className="mx-auto mt-7 flex justify-center gap-2">
              <Link
                href="/request-demo"
                className="button button-medium button-black"
                onClick={() => trackGoal("FA4ZEWM4", 0)}
              >
                Migrate your Program
                <ArrowButton isPrimary />
              </Link>
            </div>
            <div className="pb-4 pt-12 lg:pb-8 lg:pt-14">
              <div className="hidden sm:block">
                <HomeMove />
              </div>
              <div className="sm:hidden">
                <img alt="" src="img/move-to-highnote.svg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Resources */}
      <section className="hidden bg-white px-4 pb-10 antialiased sm:block lg:px-10 lg:pb-24">
        <div className="container relative mx-auto max-w-7xl items-center justify-between pb-20 lg:flex lg:pb-24">
          <div className="w-full">
            <div className="mx-auto space-y-10">
              <h3 className="font-display text-3xl lg:text-7xl">
                Read the Latest
              </h3>
            </div>
          </div>
        </div>
        <div className="container relative mx-auto flex max-w-7xl flex-col gap-5 md:flex-row lg:gap-10">
          <Link
            href="/blog/highnote-is-now-certified-on-visas-newest-fleet-payment-solution-visa-20"
            className="bg-bone rounded-highnote hover:rounded-hover group p-5 duration-100 md:w-1/3"
          >
            <img
              alt=""
              src="img/visa-highnote.png"
              className="rounded-highnote group-hover:rounded-t-hover h-56 w-full object-cover duration-100"
            />
            <h4 className="pb-5 pr-5 pt-10 text-lg">
              Highnote is Now Certified on Visa’s Newest Fleet Payment Solution:
              Visa 2.0
            </h4>
          </Link>
          <Link
            href="/blog/speedchain-selects-highnote-to-usher-in-a-new-era-in-b2b-payments"
            className="bg-bone rounded-highnote hover:rounded-hover group p-5 duration-100 md:w-1/3"
          >
            <img
              alt=""
              src="img/Speechchain_Blog@2x.jpeg"
              className="rounded-highnote group-hover:rounded-t-hover h-56 w-full object-cover duration-100"
            />
            <h4 className="pb-5 pr-5 pt-10 text-lg">
              Speedchain Selects Highnote to Usher in a New Era in B2B Payments
            </h4>
          </Link>
          <Link
            href="/blog/our-foray-into-prepaid-card-issuance-with-africas-fastest-growing-consumer-fintech-app"
            className="bg-bone rounded-highnote hover:rounded-hover group p-5 duration-100 md:w-1/3"
          >
            <img
              alt=""
              src="img/chipperBlog.jpeg"
              className="rounded-highnote group-hover:rounded-t-hover h-56 w-full object-cover duration-100"
            />
            <h4 className="pb-5 pr-5 pt-10 text-lg">
              Our Foray into Prepaid Card Issuance With Africa’s Fastest Growing
              Consumer Fintech App
            </h4>
          </Link>
        </div>
      </section>
      <section className="font-display bg-white pb-10 pt-10">
        <HomeMarquee />
      </section>
      <Footer
        formiumClient={formium}
        onNewsletterFormSuccess={handleNewsletterFormSuccess}
      />
    </>
  );
};

export default Marketing;
